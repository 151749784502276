import React, { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import MarkdownIt from 'markdown-it';
import { toKebabCase } from '../utils';

const Marker = ({
  infoWindow,
  setInfoWindow,
  selectedVendor,
  setSelectedVendor,
  vendor,
  ...options
}) => {
  const [marker, setMarker] = useState();
  const md = new MarkdownIt({ breaks: true });

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    if (!infoWindow) {
      setInfoWindow(new window.google.maps.InfoWindow());
    }

    if (marker) {
      marker.addListener('click', () => {
        setSelectedVendor(vendor.id);
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
      if (infoWindow) {
        infoWindow.close();
      }
    };
  }, [marker, infoWindow, setInfoWindow, setSelectedVendor, vendor.id]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (infoWindow) {
      infoWindow.addListener('closeclick', () => {
        setSelectedVendor(null);
      });
    }
  }, [infoWindow, setSelectedVendor]);

  useEffect(() => {
    if (selectedVendor === vendor.id) {
      const reactInfoWindowContent = (
        <div className="flex flex-col justify-between p-4 rounded-md w-full border-transparent">
          <div className="flex flex-col-reverse md:flex-row ">
            {/* <div className="w-full max-h-72 flex justify-center my-4"> */}
            {vendor.picture && (
              <div className="flex justify-center my-4 h-72 w-72">
                <image
                  src={vendor.picture[0].url}
                  alt={vendor.name}
                  className="object-contain"
                />
                {/* <GatsbyImage
                  image={getImage(vendor.picture[0].gatsbyImage)}
                  alt={vendor.name}
                /> */}
              </div>
            )}
            <div className="flex flex-col h-full font-rubik">
              <div className="flex justify-between">
                <h3 className="text-lg font-medium">{vendor.name}</h3>
              </div>
              <div className="text-sm">
                <div className="text-slate-500">
                  {vendor.phoneNumber ? (
                    <h3 className="">
                      {vendor.address} | {vendor.phoneNumber}
                    </h3>
                  ) : (
                    <h3>{vendor.address}</h3>
                  )}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: md.render(vendor.description.description),
                  }}
                  className="prose prose-sm py-4 prose-li:leading-tight prose-p:mb-0 prose-a:text-[#C6AEFD] hover:prose-a:text-slate-500 hover:prose-a:transform hover:prose-a:transition hover:prose-a:duration-200"
                />
              </div>
            </div>
          </div>
          <div className="text-sm font-normal flex justify-center w-full font-rubik mt-2">
            <a
              href={`#${toKebabCase(vendor.name)}`}
              className="border px-4 py-1 rounded-2xl text-slate-500 hover:border-b border-[#c9baf7] hover:bg-[#c9baf7] mx-2 transition duration-300"
            >
              Détails
            </a>
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                vendor.address
              )}`}
              target={'_blank'}
              rel="noreferrer"
              className="border px-4 py-1 rounded-2xl text-slate-500 hover:border-b border-[#c9baf7] hover:bg-[#c9baf7] mx-2 transition duration-300"
            >
              Directions
            </a>
          </div>
        </div>
      );

      infoWindow.close();
      infoWindow.setContent(
        ReactDOMServer.renderToString(reactInfoWindowContent)
      );
      infoWindow.open(marker.getMap(), marker);
      setSelectedVendor(vendor.id);
      const map = marker.getMap();
      map.panTo(marker.getPosition());
      if (map.getZoom() < 14) map.setZoom(14);
    }
  }, [selectedVendor]);

  return null;
};

export default Marker;
