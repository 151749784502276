import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MarkdownIt from 'markdown-it';

import { ICONS_WITH_CIRCLES } from '../utils/svgConverter';
import { isValidUrl, toKebabCase } from '../utils';

const VendorCard = ({ setSelectedVendor, vendor }) => {
  const md = new MarkdownIt({ breaks: true });

  const handleClick = () => {
    window.scrollTo(0, 140);
    setSelectedVendor(vendor.id);
  };
  return (
    <div
      id={toKebabCase(vendor.name)}
      className="flex flex-col justify-between p-4 rounded-md w-full bg-[#f2eff8] border-2 border-transparent hover:bg-[#ece6fa] hover:border-[#C6AEFD] hover:border-opacity-30 transition ease-in-out duration-300 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-medium leading-6 self-center">
            {/* <span>{index + 1}. </span> */}
            {vendor.name}
          </h3>
          <div className="ml-4">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 73.16 73.16"
            >
              <path
                fill="#C6AEFD"
                d={ICONS_WITH_CIRCLES[vendor.typeOfBusiness]}
              />
            </svg>
          </div>
        </div>
        <div className="text-sm">
          <div className="text-slate-500">
            {vendor.phoneNumber ? (
              <h3 className="">
                {vendor.address} | {vendor.phoneNumber}
              </h3>
            ) : (
              <h3>{vendor.address}</h3>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: md.render(vendor.description.description),
            }}
            className="prose prose-sm py-4 prose-li:leading-tight prose-p:mb-0 prose-a:text-[#C6AEFD] hover:prose-a:text-slate-500 hover:prose-a:transform hover:prose-a:transition hover:prose-a:duration-200"
          />
        </div>
      </div>
      <div className="text-sm flex justify-center w-full">
        {isValidUrl(vendor.instagramUrl) && (
          <a
            href={vendor.instagramUrl}
            target="_blank"
            rel="noreferrer"
            className="mx-2 hover:scale-110 transform transition duration-200"
          >
            <StaticImage
              src="../images/instagram-icon.png"
              alt="Instagram icon"
              placeholder="blurred"
              layout="fixed"
              width={40}
              height={40}
            />
          </a>
        )}
        {isValidUrl(vendor.facebookUrl) && (
          <a
            href={vendor.facebookUrl}
            target="_blank"
            rel="noreferrer"
            className="mx-2 hover:scale-110 transform transition duration-200"
          >
            <StaticImage
              src="../images/facebook-icon.png"
              alt="Facebook icon"
              placeholder="blurred"
              layout="fixed"
              width={40}
              height={40}
            />
          </a>
        )}
        {isValidUrl(vendor.websiteUrl) && (
          <a
            href={vendor.websiteUrl}
            target="_blank"
            rel="noreferrer"
            className="text-[#C6AEFD] border-[1.5px] border-[#C6AEFD] px-2 my-auto rounded-lg mx-2 h-6 leading-6 hover:text-slate-500 hover:border-slate-500 transform transition duration-200"
          >
            Site internet
          </a>
        )}
      </div>
    </div>
  );
};

export default VendorCard;
