import React, { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { graphql, useStaticQuery } from 'gatsby';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Map from '../components/Map';
import Marker from '../components/Marker';
import { ICONS_SVG } from '../utils/svgConverter';
import {
  isBrowser,
  boroughs,
  sortVendorsByBorough,
  boroughOfficialNames,
} from '../utils';
import VendorCard from '../components/VendorCard';

const render = (status) => {
  if (status === Status.LOADING) return <div>{status}</div>;
  if (status === Status.FAILURE) return <div>{status}</div>;
  return null;
};

const IndexPage = () => {
  const allVendors = useStaticQuery(graphql`
    query {
      allContentfulVendor {
        nodes {
          id
          name
          description {
            description
          }
          typeOfBusiness
          instagramUrl
          facebookUrl
          websiteUrl
          locationCoordinates {
            lat
            lon
          }
          address
          phoneNumber
          borough
          picture {
            gatsbyImage(fit: COVER, width: 1000)
            url
          }
        }
      }
    }
  `);

  useEffect(() => {
    // window.history.scrollRestoration = 'manual';
    window.history.pushState('', document.title, window.location.pathname);
    // window.scrollTo(0, 0);
  }, []);

  const sortedVendors = sortVendorsByBorough(
    allVendors.allContentfulVendor.nodes
  );

  // [START maps_react_map_component_app_state]
  const [zoom, setZoom] = useState(12); // initial zoom
  const [center, setCenter] = useState({
    lat: 45.51789,
    lng: -73.58564,
  });
  const [infoWindow, setInfoWindow] = useState();
  const [selectedVendor, setSelectedVendor] = useState(null);

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };
  // [END maps_react_map_component_app_state]

  const renderMap = () =>
    isBrowser && (
      <Wrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} render={render}>
        <Map
          center={center}
          onIdle={onIdle}
          zoom={zoom}
          fullscreenControl={false}
          mapTypeControl={false}
          style={{ flexGrow: '1', height: '100%' }}
        >
          {window &&
            window.google &&
            allVendors.allContentfulVendor.nodes.map((vendor) => (
              <Marker
                key={vendor.id}
                position={{
                  lat: vendor.locationCoordinates.lat,
                  lng: vendor.locationCoordinates.lon,
                }}
                // icon={{
                //   path: ICONS[vendor.typeOfBusiness],
                //   fillColor: '#c9baf7',
                //   strokeColor: '#c9baf7',
                //   fillOpacity: 1,
                //   strokeWeight: 1,
                //   scale: 0.7,
                //   anchor: new window.google.maps.Point(0, 0),
                //   animation: 2,
                // }}
                icon={{
                  url:
                    'data:image/svg+xml;charset=UTF-8,' +
                    encodeURIComponent(
                      ReactDOMServer.renderToString(
                        ICONS_SVG[vendor.typeOfBusiness]
                      )
                    ),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                optimized={false}
                infoWindow={infoWindow}
                setInfoWindow={setInfoWindow}
                vendor={vendor}
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
              />
            ))}
        </Map>
      </Wrapper>
    );

  return (
    <main className="font-rubik">
      <title>CARTE LA SEMAINE JAPON 2022</title>
      <div className="text-slate-800 p-2 sm:p-4 md:p-10">
        <div className="m-auto flex flex-col gap-6 justify-between items-center w-full">
          <h1 className="text-4xl p-8 tracking-[0.2em] font-light text-center">
            CARTE LA SEMAINE JAPON 2022
          </h1>
          <div className="flex h-[90vh] w-full items-center justify-center">
            {renderMap()}
          </div>
          <div className="w-full">
            <div className="">
              {boroughs.map(
                (borough) =>
                  borough in sortedVendors && (
                    <div key={borough}>
                      <h2 className="font-rubik text-4xl font-light pt-16 pb-6">
                        {boroughOfficialNames[borough].toUpperCase()}
                      </h2>
                      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-4 md:gap-6">
                        {sortedVendors[borough].map((vendor) => (
                          <VendorCard
                            vendor={vendor}
                            // index={index}
                            key={vendor.id}
                            setSelectedVendor={setSelectedVendor}
                          />
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
            {/* <h2 className="font-rubik text-3xl font-light">LE RESTE</h2>
            {allVendors.allContentfulVendor.nodes.map((vendor, index) => (
              <VendorCard vendor={vendor} index={index} />
            ))} */}
          </div>
        </div>
      </div>
      <footer className="relative text-[#f2eff8] bg-[#C6AEFD] w-full text-center text-sm tracking-wide py-2 mt-2">
        <span className="relative">©</span>{' '}
        <a href="mailto:noboru.yoshida@aminova.ca">DÉVELOPPEMENT AMINOVA</a>{' '}
        <br className="sm:hidden" />
        <span className="hidden sm:inline">-</span> TOUS DROITS RÉSERVÉS
      </footer>
    </main>
  );
};

export default IndexPage;
