import React from 'react';

const ICONS_SVG = {
  bar: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.84 44.65">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M13.37,6.8c.12,1.38.32,2.67.33,4a9.29,9.29,0,0,0,2.37,6.44c1.83,2.05,1.89,4.57,1.68,7.32H6.82V37.86H17.57c1.09,4-1.19,7.12-5.42,6.77-2.52-.22-5.07,0-7.6-.05A4.35,4.35,0,0,1,0,40Q0,31.1,0,22.19a7.69,7.69,0,0,1,2.2-5.71c1.91-1.9,1.4-4.51,1.86-6.82.18-.91.22-1.84.34-2.86Zm-.09-2.47C13.55.59,13,0,9.38,0H8.46C4.83,0,4.28.59,4.57,4.33Zm4.53,19.08H16.14V39.8h1.67Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  boutique: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.93 35.74">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M35.32,17.64V33.81c0,1.47-.46,1.93-1.94,1.93H30.87V22.38H23.45V35.69c-.4,0-.7.05-1,.05H4.5c-1.48,0-1.94-.46-1.94-1.94V17.62a6.07,6.07,0,0,1-.88.23A1.38,1.38,0,0,1,.1,16c.57-1.7,1.22-3.38,1.74-5.09a24.85,24.85,0,0,0,.7-3.41C2.68,6.42,3.17,6,4.38,6H33.5c1.3,0,1.77.51,1.84,1.78A9,9,0,0,0,35.73,10c.57,1.8,1.21,3.58,1.85,5.35.27.74.68,1.57-.12,2.1a5.81,5.81,0,0,1-2,.47ZM7.07,31.21h11.8V22.39H7.07ZM16.9,10.46c0,2.07,0,4.07,0,6.08a2.06,2.06,0,0,0,2,2.07A2,2,0,0,0,21,16.66c.07-2.06,0-4.12,0-6.2Zm12.81.08c.06.27.12.54.17.81q.51,2.73,1,5.46a2.06,2.06,0,0,0,2.43,1.74,2,2,0,0,0,1.48-2.62c-.25-.89-.49-1.78-.78-2.66s-.43-2.27-1.1-2.72-2-.11-3-.11C29.89,10.44,29.86,10.46,29.71,10.54Zm-18.83-.09c-.33,1.86-.65,3.71-1,5.56a2,2,0,1,0,4,.54c.07-.83.13-1.67.18-2.5.08-1.19.15-2.37.23-3.6Zm12.67,0c.16,2.19.24,4.3.47,6.38a1.93,1.93,0,0,0,3.07,1.38A2.2,2.2,0,0,0,28,16.64c-.21-2.08-.63-4.13-1-6.18Zm-15.36,0c-1,0-1.94,0-2.83,0a.79.79,0,0,0-1,.7c-.4,1.59-.87,3.16-1.3,4.74a1.9,1.9,0,0,0,1.4,2.49,1.93,1.93,0,0,0,2.41-1.46C7.38,14.78,7.75,12.65,8.19,10.42ZM4.29,0C3.11,0,2.58.53,2.56,1.69v.75c0,1.6.43,2,2,2H33.83A1.44,1.44,0,0,0,35.32,3c0-.37,0-.74,0-1.11C35.32.48,34.84,0,33.42,0H4.29Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  cafe: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.55 41.5">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M37.5,21.16a3.35,3.35,0,0,0-3.32-3.37c-.93,0-1.87,0-2.8,0-.52,0-.7-.11-.81-.69-.43-2.4-1.51-3.26-3.91-3.26H17.78c-3,0-6,0-9.06,0-2.45,0-3.78,1.31-3.79,3.72,0,3.9,0,7.79,0,11.69a7.3,7.3,0,0,0,7.32,7.29c3.62,0,7.24,0,10.86,0A7.23,7.23,0,0,0,30.34,31c.25-.89.47-1.35,1.61-1.47a6.06,6.06,0,0,0,5.26-4.32A11,11,0,0,0,37.5,21.16Zm-6.07,5.49c-.67.07-.88-.13-.83-.78s0-1.43,0-2.14v-.49c0-2.48.82-3.11,3.27-2.49a.82.82,0,0,1,.62.45C35.46,23.18,33.63,26.41,31.43,26.65Zm4.1,13.29c0,1.48-1,1.56-2.16,1.56q-15.63,0-31.25,0C1.06,41.5,0,41.43,0,40.07s1-1.56,2.16-1.55c5.21,0,10.41,0,15.62,0H33.41C34.47,38.52,35.49,38.58,35.53,39.94ZM11.81,10.35A6.73,6.73,0,0,1,14.5,5.07c1.27-1,2.38-2,2.33-3.89,0-.8.67-1.22,1.5-1.18a1.37,1.37,0,0,1,1.41,1.49,6.2,6.2,0,0,1-2.47,5.13c-1.33,1.1-2.57,2.15-2.55,4.14,0,.87-.85,1.25-1.72,1.07A1.34,1.34,0,0,1,11.81,10.35Zm6.93,0A5.12,5.12,0,0,1,20.91,6a3.52,3.52,0,0,0,1.85-2.89c0-.79.69-1.19,1.52-1.14a1.32,1.32,0,0,1,1.37,1.33,4.93,4.93,0,0,1-2.14,4.52,3.57,3.57,0,0,0-1.85,2.88c0,.88-.8,1.27-1.68,1.13A1.36,1.36,0,0,1,18.74,10.3Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  dessert: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.29 29.24">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M30.43,7.51H4.2C6,4.9,10.82,2.67,13.52,3.29,19,4.53,24.39,5.84,29.82,7.13c.22,0,.43.12.65.18C30.46,7.38,30.44,7.44,30.43,7.51Zm9.82,17.85c0,2.82-1,3.87-3.81,3.87H3.75C1.08,29.22,0,28.16,0,25.48,0,19.87,0,14.25.08,8.65A5.21,5.21,0,0,1,1.37,5.43,16.57,16.57,0,0,1,11.18.06c2.25-.29,4.68.51,7,1C24.74,2.58,31.3,4.21,37.88,5.7c1.73.39,2.45,1.15,2.41,3C40.18,14.26,40.26,19.81,40.25,25.36ZM4.2,7.51H30.43c0-.07,0-.13,0-.2-.22-.06-.43-.13-.65-.18C24.39,5.84,19,4.53,13.52,3.29,10.82,2.67,6,4.9,4.2,7.51ZM37.09,20.05H3.19v2.87h33.9Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  epicerie: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.76 37.79">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M11,24.57H29.34c.94,0,1.89,0,2.83,0A1.8,1.8,0,0,1,34,26.46a1.77,1.77,0,0,1-1.84,1.87c-7.08,0-14.16.06-21.24,0-2.71,0-4.45-3.27-3.15-5.74a33.12,33.12,0,0,1,1.85-3,1.58,1.58,0,0,0,.17-1.71Q7,11.3,4.28,4.67C4,4,3.74,3.69,3,3.8a5.57,5.57,0,0,1-1.29,0A1.75,1.75,0,0,1,0,2.05,1.78,1.78,0,0,1,1.6.06a36.06,36.06,0,0,1,3.89,0A1.66,1.66,0,0,1,7.05,1.44c.45,2.07,1.69,2.45,3.69,2.41,8.18-.13,16.36-.05,24.55-.05,2.31,0,3.09,1.32,2,3.37C35.16,10.94,33,14.7,31,18.48a4.14,4.14,0,0,1-4,2.33c-4.25-.06-8.5,0-12.75,0a1.26,1.26,0,0,0-1.31.71C12.36,22.48,11.72,23.42,11,24.57ZM7.55,34a3.78,3.78,0,1,0,3.79-3.79A3.71,3.71,0,0,0,7.55,34Zm22.6-3.79A3.78,3.78,0,1,0,34,34,3.72,3.72,0,0,0,30.15,30.24Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  restaurant: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.66 39.44">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M5.36,29.57c0-2.47,0-4.93,0-7.4a1.62,1.62,0,0,0-1.1-1.72,7.18,7.18,0,0,1-4.28-7C0,9.64,0,5.81,0,2,0,.94.18,0,1.42,0S3,.9,3,2c0,2.79,0,5.59,0,8.38,0,1.22.49,1.46,1.57,1.45S6,11.53,6,10.43c-.06-2.79,0-5.58,0-8.38C5.91,1,6.08,0,7.42,0S8.89,1,8.88,2.09c0,2.8,0,5.59,0,8.38,0,1.15.48,1.38,1.48,1.37s1.56-.13,1.53-1.33c-.06-2.84,0-5.69,0-8.54,0-1,.19-1.94,1.42-2s1.55.91,1.55,2c0,3.89,0,7.78,0,11.67a7,7,0,0,1-4.16,6.71,1.84,1.84,0,0,0-1.21,2c.05,4.55,0,9.1,0,13.64a11.63,11.63,0,0,1,0,1.32c-.14,1.21-.68,2.11-2,2.09a1.91,1.91,0,0,1-2-2.14c-.06-2.57,0-5.15,0-7.72ZM18.76,37c0,1.66.67,2.47,2,2.47s2-.81,2-2.46c0-3.56.08-7.13,0-10.69a2.6,2.6,0,0,1,1.64-2.79,5.61,5.61,0,0,0,3.34-5.87c-.13-1.8-.11-3.63-.39-5.4-.67-4.29-2-8.34-5.36-11.33A2.88,2.88,0,0,0,19.61.23c-.44.13-.81,1.32-.81,2-.07,5.7,0,11.4,0,17.1C18.76,25.24,18.75,31.11,18.76,37Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
  salon_de_the: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.24 31.42">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M43,8.27c-.77-1.16-1.54-.85-2.37-.15a9.43,9.43,0,0,0-2.17,3.12,14.84,14.84,0,0,1-2.07,3.6,24.73,24.73,0,0,0-2.19-4.61,2,2,0,0,0-2-1q-10.12.06-20.25,0c-1.06,0-2,.4-3.18.15A7.29,7.29,0,0,0,0,16.58a8.66,8.66,0,0,0,3.52,6.83c.76.64,1.82,1.13,2,2.24s.53,1.86,1.71,2S9,27,9.34,25.77a18.21,18.21,0,0,0,3.72,4.31,4.86,4.86,0,0,0,3.46,1.28,81.92,81.92,0,0,1,9.66,0c3,.26,4.95-1,6.75-3.06a3,3,0,0,1,1.25-1.06A9,9,0,0,0,40,20.81c.55-1.95,1-3.95,1.51-5.91s1-3.65,2.76-4.77V9.52A2.84,2.84,0,0,1,43,8.27ZM8.07,14.13a16.46,16.46,0,0,0-.63,7.71,35.62,35.62,0,0,1-2.8-2.54A3.9,3.9,0,0,1,4.05,15a3.36,3.36,0,0,1,3-2.07C7.89,12.83,8.54,12.83,8.07,14.13Zm23.77-6.8H12.48a13.3,13.3,0,0,1,6.08-3.2c.76-.19.89-.42.84-1.17a2.73,2.73,0,1,1,5.44,0c-.06.79.14,1,.86,1.15A14.22,14.22,0,0,1,31.84,7.33Z"
            fill="#c9baf7"
          />
        </g>
      </g>
    </svg>
  ),
};

const ICONS = {
  bar: 'M13.37,6.8c.12,1.38.32,2.67.33,4a9.29,9.29,0,0,0,2.37,6.44c1.83,2.05,1.89,4.57,1.68,7.32H6.82V37.86H17.57c1.09,4-1.19,7.12-5.42,6.77-2.52-.22-5.07,0-7.6-.05A4.35,4.35,0,0,1,0,40Q0,31.1,0,22.19a7.69,7.69,0,0,1,2.2-5.71c1.91-1.9,1.4-4.51,1.86-6.82.18-.91.22-1.84.34-2.86Zm-.09-2.47C13.55.59,13,0,9.38,0H8.46C4.83,0,4.28.59,4.57,4.33Zm4.53,19.08H16.14V39.8h1.67Z',
  // boutique:
  //   'M35.32,17.64V33.81c0,1.47-.46,1.93-1.94,1.93H30.87V22.38H23.45V35.69c-.4,0-.7.05-1,.05H4.5c-1.48,0-1.94-.46-1.94-1.94V17.62a6.07,6.07,0,0,1-.88.23A1.38,1.38,0,0,1,.1,16c.57-1.7,1.22-3.38,1.74-5.09a24.85,24.85,0,0,0,.7-3.41C2.68,6.42,3.17,6,4.38,6H33.5c1.3,0,1.77.51,1.84,1.78A9,9,0,0,0,35.73,10c.57,1.8,1.21,3.58,1.85,5.35.27.74.68,1.57-.12,2.1a5.81,5.81,0,0,1-2,.47ZM7.07,31.21h11.8V22.39H7.07ZM16.9,10.46c0,2.07,0,4.07,0,6.08a2.06,2.06,0,0,0,2,2.07A2,2,0,0,0,21,16.66c.07-2.06,0-4.12,0-6.2Zm12.81.08c.06.27.12.54.17.81q.51,2.73,1,5.46a2.06,2.06,0,0,0,2.43,1.74,2,2,0,0,0,1.48-2.62c-.25-.89-.49-1.78-.78-2.66s-.43-2.27-1.1-2.72-2-.11-3-.11C29.89,10.44,29.86,10.46,29.71,10.54Zm-18.83-.09c-.33,1.86-.65,3.71-1,5.56a2,2,0,1,0,4,.54c.07-.83.13-1.67.18-2.5.08-1.19.15-2.37.23-3.6Zm12.67,0c.16,2.19.24,4.3.47,6.38a1.93,1.93,0,0,0,3.07,1.38A2.2,2.2,0,0,0,28,16.64c-.21-2.08-.63-4.13-1-6.18Zm-15.36,0c-1,0-1.94,0-2.83,0a.79.79,0,0,0-1,.7c-.4,1.59-.87,3.16-1.3,4.74a1.9,1.9,0,0,0,1.4,2.49,1.93,1.93,0,0,0,2.41-1.46C7.38,14.78,7.75,12.65,8.19,10.42ZM4.29,0C3.11,0,2.58.53,2.56,1.69v.75c0,1.6.43,2,2,2H33.83A1.44,1.44,0,0,0,35.32,3c0-.37,0-.74,0-1.11C35.32.48,34.84,0,33.42,0H4.29Z',
  boutique:
    'M35.32,17.64V33.81c0,1.47-.46,1.93-1.94,1.93H30.87V22.38H23.45V35.69c-.4,0-.7.05-1,.05H4.5c-1.48,0-1.94-.46-1.94-1.94V17.62a6.07,6.07,0,0,1-.88.23A1.38,1.38,0,0,1,.1,16c.57-1.7,1.22-3.38,1.74-5.09a24.85,24.85,0,0,0,.7-3.41C2.68,6.42,3.17,6,4.38,6H33.5c1.3,0,1.77.51,1.84,1.78A9,9,0,0,0,35.73,10c.57,1.8,1.21,3.58,1.85,5.35.27.74.68,1.57-.12,2.1a5.81,5.81,0,0,1-2,.47ZM7.07,31.21h11.8V22.39H7.07ZM16.9,10.46c0,2.07,0,4.07,0,6.08a2.06,2.06,0,0,0,2,2.07A2,2,0,0,0,21,16.66c.07-2.06,0-4.12,0-6.2Zm12.81.08c.06.27.12.54.17.81q.51,2.73,1,5.46a2.06,2.06,0,0,0,2.43,1.74,2,2,0,0,0,1.48-2.62c-.25-.89-.49-1.78-.78-2.66s-.43-2.27-1.1-2.72-2-.11-3-.11C29.89,10.44,29.86,10.46,29.71,10.54Zm-18.83-.09c-.33,1.86-.65,3.71-1,5.56' +
    'c.07-.83.13-1.67.18-2.5.08-1.19.15-2.37.23-3.6Zm12.67,0c.16,2.19.24,4.3.47,6.38a1.93,1.93,0,0,0,3.07,1.38A2.2,2.2,0,0,0,28,16.64c-.21-2.08-.63-4.13-1-6.18Zm-15.36,0c-1,0-1.94,0-2.83,0a.79.79,0,0,0-1,.7c-.4,1.59-.87,3.16-1.3,4.74a1.9,1.9,0,0,0,1.4,2.49,1.93,1.93,0,0,0,2.41-1.46C7.38,14.78,7.75,12.65,8.19,10.42ZM4.29,0C3.11,0,2.58.53,2.56,1.69v.75c0,1.6.43,2,2,2H33.83A1.44,1.44,0,0,0,35.32,3c0-.37,0-.74,0-1.11C35.32.48,34.84,0,33.42,0H4.29Z',
  cafe: 'M37.5,21.16a3.35,3.35,0,0,0-3.32-3.37c-.93,0-1.87,0-2.8,0-.52,0-.7-.11-.81-.69-.43-2.4-1.51-3.26-3.91-3.26H17.78c-3,0-6,0-9.06,0-2.45,0-3.78,1.31-3.79,3.72,0,3.9,0,7.79,0,11.69a7.3,7.3,0,0,0,7.32,7.29c3.62,0,7.24,0,10.86,0A7.23,7.23,0,0,0,30.34,31c.25-.89.47-1.35,1.61-1.47a6.06,6.06,0,0,0,5.26-4.32A11,11,0,0,0,37.5,21.16Zm-6.07,5.49c-.67.07-.88-.13-.83-.78s0-1.43,0-2.14v-.49c0-2.48.82-3.11,3.27-2.49a.82.82,0,0,1,.62.45C35.46,23.18,33.63,26.41,31.43,26.65Zm4.1,13.29c0,1.48-1,1.56-2.16,1.56q-15.63,0-31.25,0C1.06,41.5,0,41.43,0,40.07s1-1.56,2.16-1.55c5.21,0,10.41,0,15.62,0H33.41C34.47,38.52,35.49,38.58,35.53,39.94ZM11.81,10.35A6.73,6.73,0,0,1,14.5,5.07c1.27-1,2.38-2,2.33-3.89,0-.8.67-1.22,1.5-1.18a1.37,1.37,0,0,1,1.41,1.49,6.2,6.2,0,0,1-2.47,5.13c-1.33,1.1-2.57,2.15-2.55,4.14,0,.87-.85,1.25-1.72,1.07A1.34,1.34,0,0,1,11.81,10.35Zm6.93,0A5.12,5.12,0,0,1,20.91,6a3.52,3.52,0,0,0,1.85-2.89c0-.79.69-1.19,1.52-1.14a1.32,1.32,0,0,1,1.37,1.33,4.93,4.93,0,0,1-2.14,4.52,3.57,3.57,0,0,0-1.85,2.88c0,.88-.8,1.27-1.68,1.13A1.36,1.36,0,0,1,18.74,10.3Z',
  dessert:
    'M30.43,7.51H4.2C6,4.9,10.82,2.67,13.52,3.29,19,4.53,24.39,5.84,29.82,7.13c.22,0,.43.12.65.18C30.46,7.38,30.44,7.44,30.43,7.51Zm9.82,17.85c0,2.82-1,3.87-3.81,3.87H3.75C1.08,29.22,0,28.16,0,25.48,0,19.87,0,14.25.08,8.65A5.21,5.21,0,0,1,1.37,5.43,16.57,16.57,0,0,1,11.18.06c2.25-.29,4.68.51,7,1C24.74,2.58,31.3,4.21,37.88,5.7c1.73.39,2.45,1.15,2.41,3C40.18,14.26,40.26,19.81,40.25,25.36ZM4.2,7.51H30.43c0-.07,0-.13,0-.2-.22-.06-.43-.13-.65-.18C24.39,5.84,19,4.53,13.52,3.29,10.82,2.67,6,4.9,4.2,7.51ZM37.09,20.05H3.19v2.87h33.9Z',
  epicerie:
    'M11,24.57H29.34c.94,0,1.89,0,2.83,0A1.8,1.8,0,0,1,34,26.46a1.77,1.77,0,0,1-1.84,1.87c-7.08,0-14.16.06-21.24,0-2.71,0-4.45-3.27-3.15-5.74a33.12,33.12,0,0,1,1.85-3,1.58,1.58,0,0,0,.17-1.71Q7,11.3,4.28,4.67C4,4,3.74,3.69,3,3.8a5.57,5.57,0,0,1-1.29,0A1.75,1.75,0,0,1,0,2.05,1.78,1.78,0,0,1,1.6.06a36.06,36.06,0,0,1,3.89,0A1.66,1.66,0,0,1,7.05,1.44c.45,2.07,1.69,2.45,3.69,2.41,8.18-.13,16.36-.05,24.55-.05,2.31,0,3.09,1.32,2,3.37C35.16,10.94,33,14.7,31,18.48a4.14,4.14,0,0,1-4,2.33c-4.25-.06-8.5,0-12.75,0a1.26,1.26,0,0,0-1.31.71C12.36,22.48,11.72,23.42,11,24.57ZM7.55,34a3.78,3.78,0,1,0,3.79-3.79A3.71,3.71,0,0,0,7.55,34Zm22.6-3.79A3.78,3.78,0,1,0,34,34,3.72,3.72,0,0,0,30.15,30.24Z',
  restaurant:
    'M5.36,29.57c0-2.47,0-4.93,0-7.4a1.62,1.62,0,0,0-1.1-1.72,7.18,7.18,0,0,1-4.28-7C0,9.64,0,5.81,0,2,0,.94.18,0,1.42,0S3,.9,3,2c0,2.79,0,5.59,0,8.38,0,1.22.49,1.46,1.57,1.45S6,11.53,6,10.43c-.06-2.79,0-5.58,0-8.38C5.91,1,6.08,0,7.42,0S8.89,1,8.88,2.09c0,2.8,0,5.59,0,8.38,0,1.15.48,1.38,1.48,1.37s1.56-.13,1.53-1.33c-.06-2.84,0-5.69,0-8.54,0-1,.19-1.94,1.42-2s1.55.91,1.55,2c0,3.89,0,7.78,0,11.67a7,7,0,0,1-4.16,6.71,1.84,1.84,0,0,0-1.21,2c.05,4.55,0,9.1,0,13.64a11.63,11.63,0,0,1,0,1.32c-.14,1.21-.68,2.11-2,2.09a1.91,1.91,0,0,1-2-2.14c-.06-2.57,0-5.15,0-7.72ZM18.76,37c0,1.66.67,2.47,2,2.47s2-.81,2-2.46c0-3.56.08-7.13,0-10.69a2.6,2.6,0,0,1,1.64-2.79,5.61,5.61,0,0,0,3.34-5.87c-.13-1.8-.11-3.63-.39-5.4-.67-4.29-2-8.34-5.36-11.33A2.88,2.88,0,0,0,19.61.23c-.44.13-.81,1.32-.81,2-.07,5.7,0,11.4,0,17.1C18.76,25.24,18.75,31.11,18.76,37Z',
  salon_de_the:
    'M43,8.27c-.77-1.16-1.54-.85-2.37-.15a9.43,9.43,0,0,0-2.17,3.12,14.84,14.84,0,0,1-2.07,3.6,24.73,24.73,0,0,0-2.19-4.61,2,2,0,0,0-2-1q-10.12.06-20.25,0c-1.06,0-2,.4-3.18.15A7.29,7.29,0,0,0,0,16.58a8.66,8.66,0,0,0,3.52,6.83c.76.64,1.82,1.13,2,2.24s.53,1.86,1.71,2S9,27,9.34,25.77a18.21,18.21,0,0,0,3.72,4.31,4.86,4.86,0,0,0,3.46,1.28,81.92,81.92,0,0,1,9.66,0c3,.26,4.95-1,6.75-3.06a3,3,0,0,1,1.25-1.06A9,9,0,0,0,40,20.81c.55-1.95,1-3.95,1.51-5.91s1-3.65,2.76-4.77V9.52A2.84,2.84,0,0,1,43,8.27ZM8.07,14.13a16.46,16.46,0,0,0-.63,7.71,35.62,35.62,0,0,1-2.8-2.54A3.9,3.9,0,0,1,4.05,15a3.36,3.36,0,0,1,3-2.07C7.89,12.83,8.54,12.83,8.07,14.13Zm23.77-6.8H12.48a13.3,13.3,0,0,1,6.08-3.2c.76-.19.89-.42.84-1.17a2.73,2.73,0,1,1,5.44,0c-.06.79.14,1,.86,1.15A14.22,14.22,0,0,1,31.84,7.33Z',
};

// Icons with circle
const ICONS_WITH_CIRCLES = {
  bar: 'M36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2ZM31.91,21.28c-.12,1-.17,1.95-.35,2.85-.46,2.31.06,4.92-1.86,6.82a7.72,7.72,0,0,0-2.2,5.72q0,8.89,0,17.81A4.36,4.36,0,0,0,32,59.05c2.54,0,5.09-.16,7.61.05,4.22.35,6.5-2.75,5.41-6.77H34.3V39H45.22c.21-2.75.15-5.27-1.68-7.32a9.32,9.32,0,0,1-2.37-6.44c0-1.29-.2-2.58-.32-3.95Zm8.84-2.48c.28-3.74-.27-4.33-3.9-4.33h-.92c-3.63,0-4.17.59-3.89,4.33Zm4.53,19.08H43.61V54.27h1.67Z',
  boutique:
    'M53.05,38.05v1c0,5.06,0,10.12,0,15.17,0,1.47-.46,1.93-1.94,1.94h-2.5V42.8H41.18V56.11c-.4,0-.69,0-1,0h-18c-1.48,0-1.94-.47-1.94-1.95V38c-.33.08-.6.19-.88.23a1.38,1.38,0,0,1-1.58-1.91c.57-1.7,1.22-3.37,1.74-5.09a24.55,24.55,0,0,0,.7-3.41c.14-1,.63-1.48,1.84-1.48H51.23c1.31,0,1.77.5,1.84,1.77a9.07,9.07,0,0,0,.39,2.26c.57,1.8,1.21,3.57,1.85,5.35.27.74.68,1.57-.12,2.1a6,6,0,0,1-2,.46ZM24.8,51.63H36.6V42.8H24.8Zm9.83-20.76c0,2.08,0,4.08,0,6.08a2,2,0,0,0,2,2.07,2,2,0,0,0,2.08-1.94c.07-2.06,0-4.12,0-6.21ZM47.44,31c.06.27.12.54.17.81.34,1.82.67,3.65,1,5.47A2.05,2.05,0,0,0,51,39a2,2,0,0,0,1.49-2.62c-.26-.89-.5-1.79-.79-2.66s-.42-2.27-1.1-2.72-2-.11-3-.12C47.62,30.85,47.6,30.88,47.44,31Zm-18.83-.08c-.33,1.86-.65,3.71-1,5.55a2,2,0,1,0,4,.54q.11-1.24.18-2.49c.08-1.19.15-2.38.23-3.6Zm12.68,0c.15,2.2.23,4.3.46,6.38a1.93,1.93,0,0,0,3.07,1.39,2.27,2.27,0,0,0,.92-1.59c-.22-2.07-.64-4.12-1-6.18Zm-15.37,0c-1,0-1.94,0-2.83,0a.79.79,0,0,0-1,.71c-.4,1.58-.87,3.15-1.3,4.73a1.91,1.91,0,0,0,1.4,2.5,1.93,1.93,0,0,0,2.41-1.46C25.11,35.19,25.48,33.07,25.92,30.84ZM22,20.42c-1.18,0-1.71.53-1.73,1.69v.74c0,1.6.43,2,2,2H51c.19,0,.38,0,.56,0a1.45,1.45,0,0,0,1.49-1.46c0-.37,0-.75,0-1.12,0-1.41-.47-1.88-1.9-1.88-4.81,0-9.62,0-14.43,0S26.92,20.41,22,20.42ZM36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2Z',
  cafe: 'M36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2Zm19.9,32a3.33,3.33,0,0,0-3.32-3.36c-.93,0-1.86,0-2.8,0-.51,0-.7-.11-.8-.69-.44-2.4-1.51-3.26-3.91-3.26H36.76c-3,0-6,0-9,0-2.45,0-3.78,1.31-3.79,3.73,0,3.89,0,7.79,0,11.68a7.29,7.29,0,0,0,7.32,7.29c3.62,0,7.24,0,10.86,0a7.23,7.23,0,0,0,7.23-5.53c.24-.89.47-1.35,1.6-1.47A6.07,6.07,0,0,0,56.2,38,11,11,0,0,0,56.48,33.93Zm-6.06,5.49c-.67.07-.88-.13-.84-.78s0-1.42,0-2.14V36c0-2.48.83-3.11,3.27-2.49a.82.82,0,0,1,.63.45C54.45,36,52.62,39.18,50.42,39.42Zm2,11.87c-5.21,0-10.42,0-15.63,0s-10.42,0-15.63,0c-1.13,0-2.19.08-2.15,1.55s1.05,1.43,2.12,1.43q15.62,0,31.25,0c1.13,0,2.19-.08,2.15-1.55S53.45,51.29,52.4,51.29ZM32,24.6c.87.18,1.73-.2,1.72-1.07,0-2,1.21-3,2.54-4.14a6.16,6.16,0,0,0,2.47-5.13,1.36,1.36,0,0,0-1.4-1.49c-.83,0-1.52.38-1.5,1.18,0,1.85-1.06,2.87-2.34,3.89a6.76,6.76,0,0,0-2.69,5.29A1.34,1.34,0,0,0,32,24.6Zm7,0c.88.15,1.65-.25,1.68-1.13A3.57,3.57,0,0,1,42.5,20.6a4.93,4.93,0,0,0,2.14-4.52,1.33,1.33,0,0,0-1.38-1.33c-.82-.05-1.5.35-1.51,1.15a3.54,3.54,0,0,1-1.85,2.88,5.13,5.13,0,0,0-2.18,4.29A1.36,1.36,0,0,0,39,24.61Z',
  dessert:
    'M36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2ZM47.11,28.39c-.22-.06-.43-.13-.65-.18-5.43-1.29-10.86-2.6-16.3-3.84-2.7-.62-7.57,1.61-9.32,4.22H47.07A1.42,1.42,0,0,1,47.11,28.39Zm9.82,1.41c0-1.87-.68-2.63-2.41-3-6.58-1.49-13.14-3.12-19.72-4.61-2.3-.52-4.73-1.32-7-1A16.62,16.62,0,0,0,18,26.51a5.25,5.25,0,0,0-1.28,3.22c-.13,5.6-.07,11.22-.06,16.82,0,2.69,1.06,3.75,3.73,3.76H53.08c2.78,0,3.8-1.05,3.81-3.87C56.9,40.89,56.82,35.34,56.93,29.8ZM30.16,24.37c5.44,1.24,10.87,2.55,16.3,3.84.22.05.43.12.65.18a1.42,1.42,0,0,0,0,.2H20.84C22.59,26,27.46,23.75,30.16,24.37ZM53.72,44H19.82V41.13h33.9Z',
  epicerie:
    'M36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2ZM29.41,41.45a1.26,1.26,0,0,1,1.31-.71c4.25,0,8.5,0,12.75,0a4.14,4.14,0,0,0,4-2.33c2.07-3.78,4.19-7.54,6.28-11.31,1.13-2,.35-3.37-2-3.37-8.19,0-16.37-.08-24.55.06-2,0-3.24-.35-3.69-2.41A1.66,1.66,0,0,0,22,20a33.66,33.66,0,0,0-3.89,0,1.76,1.76,0,0,0-1.59,2,1.75,1.75,0,0,0,1.72,1.75,5.57,5.57,0,0,0,1.29,0c.72-.1,1,.25,1.26.87q2.72,6.61,5.51,13.21a1.56,1.56,0,0,1-.17,1.7,33.28,33.28,0,0,0-1.85,3c-1.3,2.46.44,5.71,3.15,5.73,7.08.07,14.16,0,21.24,0a1.78,1.78,0,0,0,1.84-1.87,1.81,1.81,0,0,0-1.83-1.89c-.94,0-1.89,0-2.83,0H27.5C28.23,43.38,28.87,42.44,29.41,41.45ZM24.06,54a3.78,3.78,0,1,0,3.79-3.79A3.71,3.71,0,0,0,24.06,54Zm22.6-3.79a3.78,3.78,0,1,0,3.84,3.74A3.72,3.72,0,0,0,46.66,50.2Z',
  restaurant:
    'M28,47.8c0-2.46,0-4.93,0-7.39a1.6,1.6,0,0,0-1.09-1.72,7.15,7.15,0,0,1-4.28-7c0-3.83,0-7.67,0-11.5,0-1,.18-1.94,1.41-2s1.56.9,1.55,2c0,2.79,0,5.58,0,8.37,0,1.23.49,1.46,1.57,1.46s1.47-.31,1.45-1.41c-.06-2.79,0-5.59,0-8.38,0-1.09.15-2.07,1.5-2.05s1.47,1,1.46,2.09c0,2.79,0,5.59,0,8.38,0,1.15.48,1.38,1.48,1.36s1.55-.13,1.53-1.32c-.07-2.85,0-5.7,0-8.54,0-1,.19-1.94,1.42-2s1.55.9,1.54,2c0,3.89,0,7.78,0,11.67a7,7,0,0,1-4.16,6.7,1.86,1.86,0,0,0-1.22,2c0,4.55,0,9.09,0,13.64,0,.44,0,.88,0,1.31-.13,1.22-.67,2.12-2,2.1a1.91,1.91,0,0,1-2-2.14c-.07-2.58,0-5.15,0-7.73Zm13.4,7.41c0,1.65.67,2.46,2,2.47s2-.81,2-2.46c0-3.57.08-7.13,0-10.69a2.6,2.6,0,0,1,1.64-2.79,5.61,5.61,0,0,0,3.34-5.87c-.14-1.81-.11-3.63-.39-5.41-.67-4.28-2-8.33-5.36-11.33a2.87,2.87,0,0,0-2.27-.66c-.45.13-.81,1.32-.82,2-.06,5.7,0,11.4,0,17.1C41.36,43.48,41.35,49.34,41.36,55.21Zm31.8-18.63A36.58,36.58,0,1,0,36.58,73.16,36.62,36.62,0,0,0,73.16,36.58Zm-2,0A34.62,34.62,0,1,1,36.58,2,34.66,34.66,0,0,1,71.2,36.58Z',

  salon_de_the:
    'M36.58,73.16A36.58,36.58,0,1,1,73.16,36.58,36.62,36.62,0,0,1,36.58,73.16Zm0-71.2A34.62,34.62,0,1,0,71.2,36.58,34.66,34.66,0,0,0,36.58,2Zm21,28c-.77-1.17-1.53-.85-2.37-.15A9.43,9.43,0,0,0,53,32.93,14.8,14.8,0,0,1,51,36.53a25.09,25.09,0,0,0-2.2-4.61,2,2,0,0,0-2-1q-10.12.06-20.25,0c-1.06,0-2,.4-3.18.15a7.29,7.29,0,0,0-8.74,7.24,8.67,8.67,0,0,0,3.52,6.82c.76.65,1.82,1.14,2,2.24s.53,1.87,1.71,2,1.83-.67,2.15-1.86a17.77,17.77,0,0,0,3.72,4.31A4.78,4.78,0,0,0,31.13,53a84.12,84.12,0,0,1,9.66,0c3,.26,5-1,6.75-3.06a3.09,3.09,0,0,1,1.26-1.07,9,9,0,0,0,5.78-6.44c.56-2,1-4,1.52-5.91s1-3.65,2.75-4.77v-.61A2.94,2.94,0,0,1,57.56,30ZM22.69,35.82a16.32,16.32,0,0,0-.64,7.71A35.62,35.62,0,0,1,19.25,41a3.9,3.9,0,0,1-.59-4.3,3.36,3.36,0,0,1,3-2.07C22.51,34.52,23.15,34.52,22.69,35.82Zm17.62-10c-.71-.18-.92-.36-.85-1.15a2.74,2.74,0,1,0-5.45,0c.05.75-.08,1-.84,1.17A13.35,13.35,0,0,0,27.09,29H46.45A14.22,14.22,0,0,0,40.31,25.82Z',
};

export { ICONS_SVG, ICONS, ICONS_WITH_CIRCLES };
