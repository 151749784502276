import React, { useEffect, useRef, useState } from 'react';
import { createCustomEqual } from 'fast-equals';

const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  // const [currentPos, setCurrentPos] = useState({ latitude: 0, longitude: 0 });

  useEffect(() => {
    if (ref.current && !map && !!window.google) {
      setMap(
        new window.google.maps.Map(ref.current, {
          styles: [
            {
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape',
              stylers: [
                {
                  color: '#efebe2',
                },
              ],
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  color: '#efebe2',
                },
              ],
            },
            {
              featureType: 'poi.attraction',
              stylers: [
                {
                  color: '#efebe2',
                },
              ],
            },
            {
              featureType: 'poi.business',
              stylers: [
                {
                  color: '#efebe2',
                },
              ],
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  color: 'default',
                  // color: '3b78acc',
                },
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit.station.rail',
              stylers: [
                {
                  color: 'default',
                  // color: '3b78acc',
                },
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'transit.station.rail',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'transit.station.rail',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        })
      );

      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition((position) => {
      //     const pos = {
      //       lat: position.coords.latitude,
      //       lng: position.coords.longitude,
      //     };
      //     if (infoWindow) {
      //       infoWindow.setPosition(pos);
      //       infoWindow.setContent('Location found.');
      //       infoWindow.open(map);
      //       // map.setCenter(pos);
      //     }

      //     setCurrentPos({ currentPos: pos });
      //   });
      // }
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(
      new window.google.maps.LatLng(b)
    );
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b);
});

const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffectForMaps = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

const isLatLngLiteral = (obj) => {
  return (
    typeof obj === 'object' &&
    Number.isFinite(obj.lat) &&
    Number.isFinite(obj.lng)
  );
};

export default Map;
