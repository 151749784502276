const isBrowser = typeof window !== 'undefined';

const isValidUrl = (url) => url && url !== '/';

const toKebabCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-');

const boroughs = [
  'brossard',
  'cote-des-neiges/notre-dame-de-grace',
  'downtown',
  'griffintown',
  'laval',
  'mile-end',
  'petite-italie',
  'plateau',
  'pointe-claire',
  'quartier-chinois',
  'quartier-latin',
  'rosemont',
  'saint-bruno',
  'st-henri',
  'verdun',
  'vieux-montreal',
  'villeray',
  'westmount',
];

const boroughOfficialNames = {
  brossard: 'Brossard',
  'cote-des-neiges/notre-dame-de-grace': 'Côte-des-Neiges/Notre-Dame-de-Grâce',
  downtown: 'Centre-Ville',
  griffintown: 'Griffintown',
  laval: 'Laval',
  'mile-end': 'Mile End',
  'petite-italie': 'Petite Italie',
  plateau: 'Plateau-Mont-Royal',
  'pointe-claire': 'Pointe-Claire',
  'quartier-chinois': 'Quartier Chinois',
  'quartier-latin': 'Quartier Latin',
  rosemont: 'Rosemont',
  'saint-bruno': 'Saint-Bruno',
  'st-henri': 'Saint-Henri',
  verdun: 'Verdun',
  'vieux-montreal': 'Vieux-Montréal',
  villeray: 'Brossard',
  westmount: 'Westmount',
};

const sortVendorsByBorough = (data) => {
  return data.reduce((previousList, currentValue) => {
    let key = currentValue['borough'];

    if (!previousList[key]) {
      previousList[key] = [];
    }
    previousList[key].push(currentValue);

    return previousList;
  }, {});
};

export {
  isBrowser,
  isValidUrl,
  toKebabCase,
  boroughs,
  boroughOfficialNames,
  sortVendorsByBorough,
};
